* {
  box-sizing: border-box;
  margin:0;
  
}
/*FONTS*/
@font-face {
  font-family: "Rocksalt";
  src: url(fonts/RockSalt-Regular.ttf);
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(245, 245, 245)!important;

  font-family: "Rocksalt", "Montserrat", -apple-system,
  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
 }
 h1,h2,h3, h4{
  font-family: "Rocksalt";
}
 

/***********/
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
/*****************************/
/*Footer*/
.footer-container{
  font-size: 30px;
  color:white;
  background-color: black;
}
.footer-container i{
padding:10px;
}
/*Nav Bar*/
.navbar-main{
  background-color:rgb(210, 224, 228);
}


/****************************/
/****************************/
.fondo-negro{
  background-color:rgb(0,0,0)
}
/*HERO*/
.hero-imagen{
  width:100%;
  max-height:500px;
  object-fit: cover;
}
.hero-fondo{
  width:99%;
  height:99%;
  z-index: -1;
  -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  filter: blur(5px);
}
.hero-fondo-filtro{
  width:100%;
  height:100%;
  z-index: -1;
background-color: rgba(0,0,0,0.5);
}
.hero-fondo-gradient {
  height: 30%;
  background-image: linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, 1));
  z-index: 0;
  width:100%;
}
.hero-container{
margin-top: 0px;
}
.hero-container h1{
  font-size:100px;
  /*text-shadow: 2px 2px black;*/
  z-index: 3;
 
}.hero-container h2{
  font-size:50px;
  /*text-shadow: 2px 2px black;*/
  z-index: 3;
 
}
/*********/
.hero-style-p, .hero-style-r, .hero-style-s, .hero-style-v{
  position:absolute;
  bottom:0;
  width: 100%;

}


/****************************/
/****************************/
/* Breadcrumbs*/
.container-breadcrumb {
  background-color: black;
}
.container-breadcrumb a {
  padding: 5px;
  font-size: 20px;
  text-decoration: none;
  color: white;
}
.container-breadcrumb a:first-child::before {
  content: "";
}
.container-breadcrumb a::before {
  content: " / ";
}
/********TOP*****************/
.top-image-{
  height:180px;
}
.top-image-atraccion{
  height:180px;
  max-width:300px;
  object-fit: cover;
}
.top-image-ciudad{
  height:180px;
  max-width:300px;
  object-fit: cover;
}
.top-image-pais{
  height:180px;

}


/****************************/
/****************************/
/****************************/
/**NAVIGATION MENU*/
.nav-main {
  z-index: 2;
  /*background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6));*/
 /* background-color: rgba(0, 0, 0, 0.7);*/
  position: fixed;
  margin-top: 0;
  padding: 5px;
  
}

.nav-top button {
  border: none;
  font-size: 12px;
  padding: 0 10px 0 10px;
  color: white;
  text-shadow: 1px 1px rgb(91, 91, 91);

  background-color: rgba(0, 0, 0, 0);
}
.nav-top a {
  color: white;
  text-decoration: none;
}
.nav-top:hover .nav-show {
  display: block;
}
.nav-show {
  display: none;
}
.nav-content {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;

  left: 0;
  z-index: 2;
}
.nav-content,
.nav-group,
.nav-ciudad,
.nav-atraccion {
  max-height: 200px;
}
.nav-pais a {
  display: flex;
  flex-direction: row;
  padding: 0;
}
.nav-group {
  padding: 5px;
}
.nav-ciudad {
  display: flex;
  flex-flow: column wrap;
}
.nav-atraccion {
  display: flex;
  flex-flow: column wrap;

}



.nav-content a {
  text-decoration: none;
  color: black;
  font-size: 14px;
}
/****************************/
/****************************/
/**STORY **/
.img-largo-100{
  width:100%;
  height:auto;
  object-fit: cover;

}
.img-alto-100{
  height:100%;
  width:100%;
  object-fit: cover;

  

}
.story-fondo-filtro{
  width:100%;
  height:100%;
  z-index: 1;
background-color: rgba(0,0,0,0.3);


}
.story-div{
border: dotted blue;
}

.story-img-100{
  height:100%;
  width:100%;
  object-fit: cover;
 
}
.storycol-img-w100{
  height:auto;
  width:100%;
  
}
.storycol-img-h100{
  height:100%;
  width:auto;
  
}
.storycol-img-r{
  height:100%;
  width:100%;
  object-fit: cover;
}
.storycol-text{
  font-size: 12px;
  
  height:90px;
  overflow: scroll;

  
}
.storycol-div-text{


  
}

.storycol-div-container{
  max-height:250px;
 

}
.story-div-container{

}
.storycol-div-container a{
  text-decoration:none;
  color:black;
}

.story-col{
  max-height:700px;
  border: dotted purple;
}

/****************************/
/**Test Sprites**/
.flag100 {
  width: 100px;
  height: 100px;
}
.flag100-islandia {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -8px -7px no-repeat;
  background-size: 1000% 1000%;
}
.flag100-noruega {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -118px -7px no-repeat;
  background-size: 1000% 1000%;
}
.flag100-nueva-zelanda {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -229px -7px no-repeat;
  background-size: 1000% 1000%;
}
.flag100-alemania {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -339px -7px no-repeat;
  background-size: 1000% 1000%;
}
/**** flags 50**/
.flag50 {
  width: 50px;
  height: 50px;
}
.flag50-islandia {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -4px -3.5px no-repeat;
  background-size: 1000% 1000%;
}
.flag50-noruega {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -59px -3.5px no-repeat;
  background-size: 1000% 1000%;
}
.flag50-nueva-zelanda {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -115px -3.5px no-repeat;
  background-size: 1000% 1000%;
}
.flag50-alemania {
  background: url(http://mapeandonuestromundo.com/images/flags.png) -171px -3.5px no-repeat;
  background-size: 1000% 1000%;
}
/****************************/
/****************************/

/*********MIN WIDTH**************************/
/*********MIN WIDTH**************************/
/*********MIN WIDTH**************************/
/*********MIN WIDTH**************************/
@media only screen and (min-width: 575px) and (max-width: 900px) {
  .hero-container h1{font-size:10vw;}
  .hero-container h2{font-size:4vw;}
  .hero-imagen{
    min-height: 300px;;
    max-height: 400px;
  }

  

}
/************/
@media only screen and (max-width: 575px) {
  .hero-container h1{font-size:10vw;}
  .hero-container h2{font-size:6vw;}
  .hero-imagen{
    min-height: 250px;;
    max-height: 300px;
  }
  .hero-container{
    flex-wrap: wrap;
  }
  .hero-style-p, .hero-style-r, .hero-style-s, .hero-style-v{

  }

  /*****Erase***/
  .img-p{
    width:100%;
  }
  .img-r{
    width:100%;
  }
  .img-s{
    width:50%;
  }
  /**/
      /*Erase*/

  .gallery {
   
    grid-template-columns: repeat(8, 0.5fr);
    grid-template-rows: repeat(8, 3vw);
    grid-gap: 1px;
  }


  
}
/*Leaflet*/
#map { 
  height: 100%;
  width: 100%;
 }
 .mapa-div{
   height:300px;
   width:100%;
   
 }
 /**test */
 .marker-pin {
  width: 45px;
  height: 45px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -22px 0 0 -22px;
  z-index: -1;
}
/* to draw white circle*/
.marker-pin::after {
    content: '';
    width: 36px;
    height: 36px;
    margin: 4.5px 0 0 4.5px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
 }

/* to align icon*/
.custom-div-icon i {
   position: absolute;
   width: 22px;
   font-size:24px;
   left: 0;
   right: 0;
   margin: 10px auto;
   text-align: center;
}

.svg-div{
  width:360px;
  height:360px;
}
.svg-div2{
  width:24px;
  height:24px;
}
 /******/





/*****ERASE****/
.test-size{
 width:400px;
 height: 200px;

}
.test-img-full{
  
  width:100%;
  height:100%;
  object-fit: cover;
  padding:5px;
}
.test-img-hor{
  width:100%;
  height: auto;
}
.test-img-ver{
  width:auto;
  height: 100%;
}
.historia-image{
  height:400px;
  width:100%;
  object-fit: cover;
}
.test-img-history2{
  max-height: 600px;
}

.test-svg-container{
  width:360px;
  height:180px;

  
}
.test-col-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding:5px;
  
  
}
.test-text{
  font-size: 12px;
  padding:0px 10px 5px 0px;
 
}
.test-col-div{
  height: 100%;
  width: 100%;
  
  
  
}
.erase-test-img{
  height:200px;
  width:auto;
}
.test-square{
  height:100px;
 
}
.img-all1{
  max-height: 200px;
  object-fit: cover;

}
.img-p{
  width:100%;
}
.img-r{
  width:50%;
}
.img-s{
  width:33.33333%;
}
/**/
.img-all1{
  max-height: 200px;
  object-fit: cover;
  padding:0;
}

/*******/


#photos {
  /* Prevent vertical gaps */
  line-height: 0;
  
  -webkit-column-count: 5;
  -webkit-column-gap:   0px;
  -moz-column-count:    5;
  -moz-column-gap:      0px;
  column-count:         5;
  column-gap:           0px;
}

#photos img {
 /* Just in case there are inline attributes */
 width: 100% !important;
 height: auto !important;
 padding:2px;
}

@media (max-width: 1200px) {
 #photos {
 -moz-column-count:    5;
 -webkit-column-count: 5;
 column-count:         5;
 }
}
@media (max-width: 1000px) {
 #photos {
 -moz-column-count:    4;
 -webkit-column-count: 4;
 column-count:         4;
 }
}
@media (max-width: 800px) {
 #photos {
 -moz-column-count:    3;
 -webkit-column-count: 3;
 column-count:         3;
 }
 .historia-image{
  height:auto;
  width:100%;

}

}
@media (max-width: 400px) {
 #photos {
 -moz-column-count:    2;
 -webkit-column-count: 2;
 column-count:         2;
 }

}




